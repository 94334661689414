/* --------------------------------- IMPORTS -------------------------------- */
import { FC, useEffect, useState, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import applicationApi from '../../../../api/applicationApi';
import { convertIsoDateToString } from '../../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Application } from '../../../../types';
import { createRenewalApp } from '../../../../redux/slices/UserSlice';
import { TEXT_CANT_RENEW_WARNING, REGISTRATION_EMAIL, TEXT_RENEW_BREADCRUMB1, LINK_REGISTRATIOM_RENEWAL_DETAILS2 } from '../../../../constants';
import { RenewalAppButtons } from './RenewalAppButtons';

/* -------------------------------------------------------------------------- */

/* --------------------------------- HELPERS -------------------------------- */

const handleErrorMessage = (message: string | null, defaultMsg: string) => message || defaultMsg;

const processApiResponse = (response: any, setApplications: Function, setErrMsg: Function) => {
    if (!response.success) {
        setErrMsg(handleErrorMessage(response.message, 'An error occurred, please try again later'));
    } else {
        response.data ? setApplications(response.data) : setErrMsg('No data found');
    }
};

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RenewalApplicationsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [loadingCreateApp, setLoadingCreateApp] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [errMsgCreateApp, setErrMsgCreateApp] = useState<string | null>(null);
    const [applications, setApplications] = useState<Application[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const goToNextPage = () => {
        navigate(LINK_REGISTRATIOM_RENEWAL_DETAILS2.value);
    };

    useEffect(() => {
        const getApplicationsAsync = async () => {
            try {
                const response = await applicationApi.getRenewalAppsByUserId();
                if (response) {
                    processApiResponse(response, setApplications, setErrMsg);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                setErrMsg(err instanceof Error ? err.message : 'An error has occurred, please try again later');
            } finally {
                setLoading(false);
            }
        };

        getApplicationsAsync();
        return () => {
            setLoading(false);
            setErrMsg(null);
            setApplications([]);
        };
    }, [setErrMsg, setApplications]);

    const createNewApplication = async () => {
        try {
            setLoadingCreateApp(true);
            const { value: category } = user.statusHistory.category;
            const { value: subcategory } = user.statusHistory.subcategory;

            if (category && subcategory) {
                const response = await applicationApi.checkIfUserHasRenewalAppStarted();
                if (response) {
                    processApiResponse(response, setApplications, setErrMsgCreateApp);

                    if (response.data === false) {
                        const newAppResponse = await dispatch(
                            createRenewalApp({
                                pfn_categoryid: category,
                                pfn_subcategoryid: subcategory,
                            })
                        );
                        const { payload } = newAppResponse;
                        if (!payload?.success) {
                            setErrMsgCreateApp(handleErrorMessage(payload?.message, 'An error occurred, please try again later'));
                        } else {
                            navigate(`/applications/renewal/${payload?.data.pfn_applicationid}`);
                        }
                    } else {
                        setErrMsgCreateApp('You already have a renewal application started for this year');
                        navigate(`/applications/history`);
                    }
                } else {
                    setErrMsgCreateApp('An error has occurred, please try again later');
                }
            } else {
                setErrMsgCreateApp('No category and/or subcategory found on user');
            }
        } catch (err) {
            setErrMsgCreateApp(err instanceof Error ? err.message : 'An error has occurred, please try again later');
        } finally {
            setLoadingCreateApp(false);
        }
    };

    const generateGeneralInfo = (
        renewalYear: string,
        createNewApplication: () => Promise<void>,
        loadingCreateApp: boolean,
        errMsgCreateApp: string | null,
        applications: Application[]
    ) => {
        const application = applications.find(a => a._pfn_renewalyearid_value_Formatted &&a._pfn_renewalyearid_value_Formatted === renewalYear && (a.statuscode_Formatted === 'Active' || a.statuscode_Formatted === 'Ready to be finalized')) || null;

        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>
                        Application to the Registrar for Registration Renewal of January 1 – December 31, 2025
                    </h3>
                </div>
                <div className='card-body pt-0'>
            <p> All registrants wishing to remain registered with the CVBC for {renewalYear} must complete the CVBC Registration Renewal Process as described below, within the prescribed timeframe. All parts of the registration renewal process must be completed to ensure that your registration renewal is granted and to avoid penalties and possible cancellation.</p>
    
                <ol type="A">
                    <li> If you want to <b>change to a different registration status</b> for January 1, 2025 or if you don’t intend to renew your registration (you wish to resign your registration), contact the CVBC at <a href={`mailto:${REGISTRATION_EMAIL.value}`}>{REGISTRATION_EMAIL.value}</a> for assistance and directions as soon as possible so that you don’t miss the deadlines.</li> 
                    <br/>
                    <li>If you want to <b>renew your current form and class of registration</b>, you must complete all of the following requirements (as required by s. 2.27 of the CVBC bylaws) by <b>November 30th, 2024</b>:</li>
                    <ol type="1">
                        <li>Complete the online application for registration renewal.</li> 
                        <li>Pay the annual registration fee (invoice will appear in your online account after you have completed the application form)</li>
                        <li>Pay any other outstanding fees, late fees, special fees or assessments, or other debt owed to the College (any outstanding invoices should be found in your online account)</li>
                        <br/>
                        <p><b>AND then,</b></p>
                        <li>You must also</li>
                        <ol type="a">
                            <li><b>complete</b> the minimum Continuing Education hours required by the CVBC’s Continuing Competence Program for the 2023-2024 CE cycle by <b>December 31, 2024</b>, AND</li>
                            <li><b>report</b> your completed CE sessions through your online registrant account by no later than the end of the first business day of 2025 (<b>Thursday, January 2, 2025</b>), AND</li>
                            <li>either <b>upload</b> your CE certificates to your account, or be able and prepared to provide them promptly to the CVBC upon request.</li>
                        </ol>
                    </ol>
                </ol>

                <p>Should you fail to complete requirements 1 – 3 by the <b>November 30th, 2024 registration renewal deadline, a late fee equal to 10%</b> of your annual registration fee will be assessed. Late registration renewal can then be completed between December 1, 2024 and the end of the first business day of 2025 (Thursday, January 2, 2025) by completing requirements 1-4 (which will include payment of the additional late fee).</p>
                <p>Should you fail to complete all the registration renewal requirements (1 – 4) by the end of the first business day in January (<b>Thursday, January 2, 2025</b>), your <b>registration will be CANCELLED</b> (effective January 1, 2025) for a failure to complete your renewal.</p>
                <p>If your registration is cancelled for a failure to complete the renewal process:</p>

                <ul>
                    <li>You will be eligible for <b>reinstatement</b> of your registration IF the <b>ONLY</b> outstanding requirement of your registration renewal as of January 2nd was <b>payment of the annual registration fee and 10% late fee</b></li>
                    <ul>
                        <li>reinstatement can be granted by payment of your annual registration fee plus a 25% reinstatement fee (the 10% late fee no longer applies)</li>
                    </ul>
                </ul>

                <p>BUT</p>

                <ul>
                    <li>if any other requirements of the registration renewal process were part of the reason for cancellation, there is NO option for reinstatement. Individuals whose registration is cancelled for a failure to complete the registration renewal process in full<b>*</b> must apply for and receive new registration with the CVBC (satisfying all registration criteria required of all new registrants) before they may return to the practice of veterinary medicine in BC.</li>
                    <br/>
                    <b>*</b> A completed registration renewal process consists of:
                    <br/>
                    <ul>
                        <li>Completion of the application for registration renewal form,</li>
                        <li>Declared compliance the Act, bylaws and regulations of the CVBC, as well as any limits or conditions imposed upon the registrant,</li>
                        <li>Payment of any other outstanding fees (other than registration fees), <b>and</b></li>
                        <li>Reporting of your minimum required CE hours in your online account.</li>
                    </ul>
                </ul>

                <div className="text-end">
                    <RenewalAppButtons application={application} goToNextPage={() => navigate(LINK_REGISTRATIOM_RENEWAL_DETAILS2.value)} />
                </div>
        </div>
    </div>
);
};
    const NoApplicationsSection = () => (
        <Fragment>
            <div>
                You have not yet started a renewal application for next year.            
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            {user.statusHistory.subcategory.formatted === 'Applicant' ? (
                <NoAccessSection />
            ) : user.renewalYear && user.pfn_canrenew ? (
                <Fragment>
                    {generateGeneralInfo(
                        user.renewalYear,
                        createNewApplication,
                        loadingCreateApp,
                        errMsgCreateApp,
                        applications
                    )}
                    {loading ? <p>Retrieving applications, please wait...</p> : errMsg ? <p>{errMsg}</p> : '' }
                </Fragment>
            ) : (
                <NoRenewalSection />
            )}
        </Fragment>
    );
};

/* -------------------------------- SUB-COMPONENTS -------------------------------- */

const NoAccessSection = () => (
    <Fragment>
        <div className='mb-5'>
            You are not yet registered, so there is no need to visit this page. Please use the navigation menu or go back to the dashboard to select a different option.
        </div>
        <div className='pt-lg-10 mb-10'>
            <div className='text-center'>
                <Link to='/' className='btn btn-lg btn-primary fw-bolder'>Go to Dashboard</Link>
            </div>
        </div>
    </Fragment>
);
const NoRenewalSection = () => (
    <div className='card-body pt-0'>
        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-3'>
            <div className='note-card-inner flex-grow-1 me-2'>{TEXT_CANT_RENEW_WARNING.value}</div>
        </div>
    </div>
);

/* --------------------------------- WRAPPER -------------------------------- */

const RegistrationRenewalDetails1Wrapper: FC = () => (
    <Fragment>
        <PageTitle breadcrumbs={[]}>{TEXT_RENEW_BREADCRUMB1.value}</PageTitle>
        <RenewalApplicationsPage />
    </Fragment>
);

/* --------------------------------- EXPORTS -------------------------------- */

export {RegistrationRenewalDetails1Wrapper };



/* --------------------------------- IMPORTS -------------------------------- */
import { FC, useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import applicationApi from '../../../../api/applicationApi';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Application } from '../../../../types';
import { createRenewalApp } from '../../../../redux/slices/UserSlice';
import { REGISTRATION_EMAIL, TEXT_RENEW_BREADCRUMB1 } from '../../../../constants';

/* -------------------------------- COMPONENTS ------------------------------- */

const StartNewApplicationButton: FC<{
    renewalYear: string;
    createNewApplication: () => Promise<void>;
    loadingCreateApp: boolean;
    errMsgCreateApp: string | null;
}> = ({ renewalYear, createNewApplication, loadingCreateApp, errMsgCreateApp }) => (
    <div className='mt-2'>
        <button
            type='button'
            className='btn btn-primary my-1'
            onClick={createNewApplication}
            disabled={loadingCreateApp}
        >
            {!loadingCreateApp ? (
                <span className='indicator-label'>
                    Start Renewal Application
                </span>
            ) : (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
        </button>
        {errMsgCreateApp && <div>{errMsgCreateApp}</div>}
    </div>
);

/* --------------------------------- WRAPPER -------------------------------- */

const generateGeneralInfo = (
salutation: string, 
firstname: string, 
lastname: string, 
renewalYear: string, 
createNewApplication: () => Promise<void>, 
loadingCreateApp: boolean,
errMsgCreateApp: string | null,
category: { value: string | null; formatted: string | undefined; }, 
subcategory: { value: string | null; formatted: string | undefined; }) => (
    <div className='card card-xl mb-5 mb-xl-8'>
        <div className='card-header border-0'>
            <h3 className='card-title fw-bolder text-dark'>
            {`${renewalYear} Registration Renewal Application`}
            </h3>
        </div>

        <div className='card-body pt-0'>
            <div>
                {salutation} {firstname} {lastname},
                <p>Your current registration status is: <b>{category.formatted} ({subcategory.formatted})</b>.</p>
                <div>Please determine which of the following applies to you:
                <br/><br/>
                    <ul>
                        <li>
                            I wish to renew my {category.formatted} ({subcategory.formatted}) registration with the CVBC for the {renewalYear} registration year (January 1 – December 31, 2025)
                            <div>
                                ➔ Proceed with the application and follow all instructions
                                <br/>
                                <StartNewApplicationButton
                                    renewalYear={renewalYear}
                                    createNewApplication={createNewApplication}
                                    loadingCreateApp={loadingCreateApp}
                                    errMsgCreateApp={errMsgCreateApp}
                                /><br/>
                            </div>
                        </li>
                        <li>
                            I would like to change my registration status for next year (e.g., transfer to another class of registration, or resign my registration)
                            <div>
                                ➔ Contact the CVBC office to discuss your plans and receive instructions before doing anything further (send an email to <a href={`mailto:${REGISTRATION_EMAIL.value}`}>{REGISTRATION_EMAIL.value}</a>)
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

);

const RegistrationRenewalDetails2: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [loadingCreateApp, setLoadingCreateApp] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [errMsgCreateApp, setErrMsgCreateApp] = useState<string | null>(null);
    const [applications, setApplications] = useState<Application[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getApplicationsAsync = async () => {
            try {
                const response = await applicationApi.getAppsByUserId();
                if (response.success && response.data) {
                    setApplications(response.data);
                } else {
                    setErrMsg(response.message || 'No data found');
                }
            } catch (error) {
                setErrMsg(error instanceof Error ? error.message : 'An error has occurred');
            } finally {
                setLoading(false);
            }
        };

        getApplicationsAsync();
    }, []);

    const createNewApplication = async () => {
        try {
            setLoadingCreateApp(true);
            const { statusHistory } = user;
            const category = statusHistory?.category?.value;
            const subcategory = statusHistory?.subcategory?.value;

            if (!category || !subcategory) {
                setErrMsgCreateApp('No category and/or subcategory found on user');
                return;
            }

            const response = await applicationApi.checkIfUserHasRenewalAppStarted();
            if (!response.success) {
                setErrMsgCreateApp(response.message || 'An error occurred');
            } else if (!response.data) {
                const newAppResponse = await dispatch(
                    createRenewalApp({
                        pfn_categoryid: category,
                        pfn_subcategoryid: subcategory,
                    })
                );
                const { payload } = newAppResponse;
                if (!payload?.success) {
                    setErrMsgCreateApp(payload?.message || 'An error occurred');
                } else {
                    navigate(`/applications/renewal/${payload?.data.pfn_applicationid}`);
                }
            } else {
                setErrMsgCreateApp('You already have a renewal application started for this year');
                navigate(`/applications/history`);
            }
        } catch (error) {
            setErrMsgCreateApp(error instanceof Error ? error.message : 'An error has occurred');
        } finally {
            setLoadingCreateApp(false);
        }
    };

    return (
        <Fragment>
            <PageTitle breadcrumbs={[]}>{TEXT_RENEW_BREADCRUMB1.value}</PageTitle>
            {loading ? (
                <p>Loading applications, please wait...</p>
            ) : errMsg ? (
                <p>{errMsg}</p>
            ) : (
                <Fragment>
                    {user.renewalYear && applications.length > 0 && applications[0].statuscode_Formatted !== 'Active' ? (
                        <Fragment>
                            {generateGeneralInfo(
                                user.pfn_salutation_Formatted ?? '',
                                user.firstname ?? '',
                                user.lastname ?? '',
                                user.renewalYear,
                                createNewApplication,
                                loadingCreateApp,
                                errMsgCreateApp,
                                user.statusHistory.category,
                                user.statusHistory.subcategory
                            )}

                        </Fragment>
                    ) : (
                        <div>
                            <p>No active applications or no need to renew at this time.</p>
                            <br />
                            <button type="button" className="btn btn-primary fw-bold mx-2" onClick={() => navigate('/')}>
                                Return to Dashboard
                            </button>
                        </div>

                    )}
                </Fragment>
            )}
        </Fragment>
        
    );
};


/* --------------------------------- EXPORTS -------------------------------- */

export { RegistrationRenewalDetails2 as RegistrationRenewalDetails2Wrapper };